<template>
  <div>

    <v-card
    color="accent"
    outlined
    @click="redirectToEventInfo"
    class="mx-2 my-3"
    >

      <v-card-title class="text-body-1 font-weight-medium font-italic my-n2">
        {{student}}
      </v-card-title>
      <v-card-subtitle>
        {{teacher}}
      </v-card-subtitle>
      <v-card-text class="text-caption my-n3">
        {{event.date | formatDateWithWeekday }}: {{event.start}} - {{event.end}}. {{event.subject}}.
      </v-card-text>
    
    </v-card>

  </div>
</template>

<script>

export default {
    name: 'EventInfos',
    props: ["event", "color"],
    data() {
        return {
          student: "",
          teacher: "",
        }
    },

    created() {
      this.getTeacherName();
      this.getStudentName();
    },

    methods: {

      async getTeacherName() {
        const teacherdoc = await this.event.teacherRef.get();
        if (teacherdoc.exists) {
          this.teacher = teacherdoc.data().firstname + " " + teacherdoc.data().lastname;
        } else { console.log('No such document') }
      },

      async getStudentName() {
        const studentDoc = await this.event.studentRef.get();
        if (studentDoc.exists) {
          this.student = studentDoc.data().firstname + " " + studentDoc.data().lastname;
        } else { console.log('No such document') }
      },

      redirectToEventInfo() {
        /* redirect to EventInfo view and pass with it the id of the event */
        this.$router.push('/event/' + this.event.id);
      }
    }
}
</script>

<style scoped>

</style>