<template>
<div>
  <v-card max-width="370" class="mx-auto mt-5" flat>

    <Errors v-bind:errors="errors"/>

    <v-card-title>
        Stunden von 
        <div v-if="role == 'student'" class="font-italic ml-1">
          {{student.firstname}} {{student.lastname}}
        </div>
        <div v-if="role == 'teacher'" class="ml-1">
          {{teacher.firstname}} {{teacher.lastname}}
        </div>
    </v-card-title>

    <!-- month Toolbar -->
    <v-toolbar flat dense class="mt-n3">
        <v-icon @click="previousMonth">
          mdi-chevron-left
        </v-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title @click="toggleMonthPicker">
        {{this.month | formatMonth}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
        <v-icon @click="nextMonth">
          mdi-chevron-right
        </v-icon>
    </v-toolbar>

    <v-row v-if="displayMonthPicker" justify="center">
      <v-date-picker
        v-model.trim="month"
        type="month"
        header-color="accent"
        color="primary"
      ></v-date-picker>
    </v-row>

    <v-card-text v-if="events.length">
      Gesamt: {{this.sumOfLessons}} Stunden

      <div 
        v-for="event in events" 
        v-bind:key="event.id"
        >
          <EventInfos v-bind:event="event"/>
      </div>
    </v-card-text>

    <v-card-text v-else>
      Keine Stunden in diesem Monat.
    </v-card-text>

    


  </v-card>
</div>
</template>

<script>
import { eventsCollection, studentsCollection, teachersCollection, auth } from '@/firebase';
import {getTeacher, getStudent, getInsituteSettings} from '@/assets/js/dataUtils.js';
import {calculateLastDayOfMonth, addMonths, calculateTimeBetween} from '@/assets/js/timeUtils.js';
import Errors from '@/components/Errors.vue';
import EventInfos from '@/components/EventInfos.vue';

export default {
  name: 'EventsOf',
  props: ['role', 'uid'],

  components: {
    Errors,
    EventInfos,
  },

  data() {
    return {
      errors: [],
      student: {},
      teacher: {},
      events: [],
      sumOfLessons: 0,
      month: new Date().toISOString().substr(0,7), //todo this month
      displayMonthPicker: false,
    }
  },

  watch: {
    month: function() {
      this.displayMonthPicker = false;
      this.getEventsInMonth();
    }
  },

  async created() {
    switch(this.role) {
      case 'student':
        this.student = await getStudent(this.uid);
        break;
    
      case 'teacher':
        this.teacher = await getTeacher(this.uid);
        break;
    }

    //todo later weg und über watcher
    this.getEventsInMonth();
  },

  methods: {

    /**
     * @description Gets the last 20 events of this student to display them
     */
    async getEventsInMonth(){
      this.events = [];
      this.sumOfLessons = 0;
      const instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
      const minEventDuration = (await getInsituteSettings()).minEventDuration;

      const firstDayOfMonth = this.month + '-01';
      const lastDayOfMonth = calculateLastDayOfMonth(this.month);
      
      switch(this.role) {
        case 'student':
          eventsCollection
            .where("instituteId", "==", instituteId)
            .where("studentRef", "==", studentsCollection.doc(this.student.id))
            .where('date', '>=', firstDayOfMonth)
            .where('date', '<=', lastDayOfMonth)
            .orderBy('date')
            .orderBy('start')
            .get()
            .then(snapshot => {
              this.events = [];
              this.sumOfLessons = 0;
              snapshot.forEach(doc => {
                let event = doc.data();
                event.id = doc.id;
                this.events.push(event);
                let duration = calculateTimeBetween(event.start, event.end);
                this.sumOfLessons = this.sumOfLessons + Math.round(duration / minEventDuration * 10) / 10;
              })
            })

          break;

        case 'teacher':
          eventsCollection
            .where("instituteId", "==", instituteId)
            .where("teacherRef", "==", teachersCollection.doc(this.teacher.id))
            .where('date', '>=', firstDayOfMonth)
            .where('date', '<=', lastDayOfMonth)
            .orderBy('date')
            .orderBy('start')
            .get()
            .then(snapshot => {
              this.events = [];
              this.sumOfLessons = 0;
              snapshot.forEach(doc => {
                let event = doc.data();
                event.id = doc.id;
                this.events.push(event);
                let duration = calculateTimeBetween(event.start, event.end);
                this.sumOfLessons = this.sumOfLessons + Math.round(duration / minEventDuration * 10) / 10;
              })
            })
          break;
      }
      
    },

    toggleMonthPicker() {
      this.displayMonthPicker = !this.displayMonthPicker;
    },

    previousMonth() {
      this.month = addMonths(this.month, -1);
    },

    nextMonth() {
      this.month = addMonths(this.month, 1);
    }

  }
}
</script>